import "./infiniteslider.css";

const InfiniteSlider = ({ allLogos }) => (
  <div className="infiniteslider">
    <div className="infiniteslide-track">
      {allLogos.map((logo) => (
        <div
          className="infiniteslide"
          style={{ width: "250px", height: "100px" }}
        >
          <img
            src={logo}
            style={{
              objectFit: "contain",
            }}
            width="100%"
            height="100%"
            // width="220"
            alt=""
          />
        </div>
      ))}
      {allLogos.map((logo) => (
        <div
          className="infiniteslide"
          style={{ width: "250px", height: "100px" }}
        >
          <img
            src={logo}
            style={{
              objectFit: "contain",
            }}
            height="100%"
            width="100%"
            alt=""
          />
        </div>
      ))}
    </div>
  </div>
);

export default InfiniteSlider;
