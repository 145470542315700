import useTheme from "@mui/system/useTheme";

import logo1 from "../../../../images/home/brandlogos/1.webp";
import logo2 from "../../../../images/home/brandlogos/2.webp";
import logo3 from "../../../../images/home/brandlogos/3.webp";
import logo4 from "../../../../images/home/brandlogos/4.webp"; 
import logo5 from "../../../../images/home/brandlogos/5.webp";
import logo6 from "../../../../images/home/brandlogos/6.webp";
import logo7 from "../../../../images/home/brandlogos/7.webp";
import logo8 from "../../../../images/home/brandlogos/8.webp";
import logo9 from "../../../../images/home/brandlogos/9.webp";
import logo10 from "../../../../images/home/brandlogos/10.webp";
import logo11 from "../../../../images/home/brandlogos/11.webp";
import logo12 from "../../../../images/home/brandlogos/12.webp";
import logo13 from "../../../../images/home/brandlogos/13.webp";
import logo14 from "../../../../images/home/brandlogos/14.webp";
import logo15 from "../../../../images/home/brandlogos/15.webp";
import logo16 from "../../../../images/home/brandlogos/16.webp";
import logo17 from "../../../../images/home/brandlogos/17.webp";
import logo18 from "../../../../images/home/brandlogos/18.webp";
import logo19 from "../../../../images/home/brandlogos/19.webp";
import logo20 from "../../../../images/home/brandlogos/20.webp";
import logo21 from "../../../../images/home/brandlogos/21.webp";
import logo22 from "../../../../images/home/brandlogos/22.webp";
import logo23 from "../../../../images/home/brandlogos/23.webp";
import logo24 from "../../../../images/home/brandlogos/24.webp";
import logo25 from "../../../../images/home/brandlogos/25.webp";
import logo26 from "../../../../images/home/brandlogos/26.webp";
import logo27 from "../../../../images/home/brandlogos/27.webp";
import logo28 from "../../../../images/home/brandlogos/28.webp";
import logo29 from "../../../../images/home/brandlogos/29.webp";
import logo30 from "../../../../images/home/brandlogos/30.webp";
import logo31 from "../../../../images/home/brandlogos/31.webp";
import logo32 from "../../../../images/home/brandlogos/32.webp";
import logo33 from "../../../../images/home/brandlogos/33.webp";
import logo34 from "../../../../images/home/brandlogos/34.webp";
import logo35 from "../../../../images/home/brandlogos/35.webp";
import logo36 from "../../../../images/home/brandlogos/36.webp";
import logo37 from "../../../../images/home/brandlogos/37.webp";
import logo38 from "../../../../images/home/brandlogos/38.webp";
import logo39 from "../../../../images/home/brandlogos/39.webp";
import logo40 from "../../../../images/home/brandlogos/40.webp";
import logo41 from "../../../../images/home/brandlogos/41.webp";
//import logo43 from "../../../../images/home/brandlogos/43.webp";
//import logo44 from "../../../../images/home/brandlogos/44.webp";
//import logo45 from "../../../../images/home/brandlogos/45.webp";


import InfiniteSlider from "../../../../Pages/InfiniteSlider/infiniteslider";

import { Container } from "@mui/material";

const IndustryLeaders = () => {
  const allLogos = [
    //logo43,
    //logo44,
    //logo45,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo12,
    logo13,
    logo14,
    logo15,
    logo16,
    logo17,
    logo18,
    logo19,
    logo20,
    logo21,
    logo22,
    logo23,
    logo24,
    logo25,
    logo26,
    logo27,
    logo28,
    logo29,
    logo30,
    logo31,
    logo32,
    logo33,
    logo34,
    logo35,
    logo36,
    logo37,
    logo38,
    logo39,
    logo40,
    logo41,
    
   
  ];
  const theme = useTheme();
  return (
    <section
      class="partners"
      style={{
        paddingTop: "1%",
        paddingBottom: "5%",
        backgroundColor: "#F7F5FF",
      }}
    >
      <div className="" style={{}}>
        <div className="section-text">
          <h2
            style={{
              fontFamily: "Poppins , sans-serif",
              fontWeight: "700",
            }}
          >
            Trusted by Industry Leaders
          </h2>
          <br></br>
        </div>
        <InfiniteSlider allLogos={allLogos} />
      </div>
    </section>
  );
};

export default IndustryLeaders;
