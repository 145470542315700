// ContactUs.js
import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  InputLabel,
  FormControl,
} from "@mui/material";
import { CiMail } from "react-icons/ci";
import { FaPhone } from "react-icons/fa";
import { useState } from "react";

import { FaWhatsapp } from "react-icons/fa";

import { FaLocationDot } from "react-icons/fa6";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { Select, MenuItem } from "@mui/material";
import { useEffect } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Notification from "../../Components/notification/notification";
import "./ContactUs.css";
import { useAppContext } from "../../Context/notification-context";

const styles = {
  whiteBorder: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey",
      },
    },
    "& .MuiInputLabel-root": {
      color: "grey",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
  },
};

const defaultFormData = {
  name: "",
  email: "",
  phone: "",
  numberOfBrands: "",
  numberOfStores: "",
  brandName: "",
  city: "",
  kpi: "contact",
};

function ContactUs({ classes }) {
  const [notification, setNotification] = useState(false);
  const { loading, setLoading, loadingContent, setLoadingContent } =
    useAppContext();

  useEffect(() => {
    document.title = "Contact Us | Restaverse";
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [formData, setFormData] = useState({
    ...defaultFormData,
  });

  const notify = () => {
    setNotification(true);

    setTimeout(() => {
      console.log("Delayed for 1 second.");
      setNotification(false);
    }, 4000);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormData(defaultFormData);
    setNotification(true);
    setLoading(true);
    setLoadingContent("Submitting your form");

    const headers = {
      "Content-Type": "application/json", // Assuming JSON data is being sent
    };

    console.log(formData);

    fetch("https://prod.restaverse.com/api/save-job-data", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data["message"] == "Your response data") {
          setLoading(false);
          setLoadingContent("Your form has been submitted.");
          setTimeout(() => {
            console.log("Delayed for 1 second.");
            setNotification(false);
          }, 3000);
        }
        // setFormData(defaultFormData);
        // notify()
      })
      .catch((error) => {
        console.error("Error saving job data:", error);
      });
  };
  return (
    <>
      {notification && <Notification />}
      <Container className="contact-container">
        <Box>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <Box className="contact-section">
                <Typography variant="h2" gutterBottom>
                  Contact Us
                </Typography>
                <Typography paragraph style={{ color: "grey" }}>
                  Growth Partner for Restaurants - We help restaurants manage,
                  operate and grow their business on aggregator platforms!
                </Typography>
              </Box>
              <Box className="contact-info">
                {renderContactInfo(
                  FaLocationDot, // Assuming this is a custom icon
                  "Our Address",
                  "Mumbai",
                  "Ahmedabad",
                  "Delhi NCR",
                  "https://g.co/kgs/vH7K7xS",
                  "https://g.co/kgs/poGoCTT",
                  "https://g.co/kgs/ri3dFE9"
                )}

                {renderContactInfo(
                  CiMail, // Assuming this is a custom icon
                  "Email",
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=info@restaverse.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@restaverse.com
                  </a>
                )}
                {renderContactInfo(
                  FaWhatsapp, // Assuming this is a custom icon
                  "Whatsapp",
                  <a
                    href="http://wa.me/919137323302"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +91 91373 23302
                  </a>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              marginTop={{ xs: 10, md: 0 }}
              className={`contactus-form ${classes.whiteBorder}`}
            >
              <form onSubmit={handleSubmit}>
                <Box className="hello-world">
                  <Typography
                    variant="h4"
                    style={{ animation: "fadeDown 1.5s forwards" }}
                  >
                    Ready to get started?
                  </Typography>

                  {/* Flex Container for the first two TextFields */}
                  <Box style={{ display: "flex", gap: "16px" }}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </Box>

                  <Box style={{ display: "flex", gap: "16px" }}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <TextField
                      label="Phone"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      type="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </Box>

                  <Box style={{ display: "flex", gap: "16px" }}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                    >
                      <InputLabel htmlFor="number-of-brands-label">
                        Number of Brands
                      </InputLabel>
                      <Select
                        label="Number of Brands"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        required
                        name="numberOfBrands"
                        value={formData.numberOfBrands}
                        onChange={handleChange}
                      >
                        {/* Custom options for the dropdown */}
                        <MenuItem value={"1"}>1</MenuItem>
                        <MenuItem value={"2-5"}>2-5</MenuItem>
                        <MenuItem value={"6-10"}>6-10</MenuItem>
                        <MenuItem value={"more than 10"}>more than 10</MenuItem>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>

                    <FormControl
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                    >
                      <InputLabel htmlFor="number-of-stores-label">
                        Number of Stores
                      </InputLabel>
                      <Select
                        label="Number of Stores"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        required
                        name="numberOfStores"
                        value={formData.numberOfStores}
                        onChange={handleChange}
                      >
                        {/* Custom options for the dropdown */}
                        <MenuItem value={"less than 5"}>less than 5</MenuItem>
                        <MenuItem value={"6-10"}>6-10</MenuItem>
                        <MenuItem value={"11-25"}>11-25</MenuItem>
                        <MenuItem value={"26-50"}>26-50</MenuItem>
                        <MenuItem value={"51-100"}>51-100</MenuItem>
                        <MenuItem value={"more than 100"}>
                          more than 100
                        </MenuItem>

                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box style={{ display: "flex", gap: "16px" }}>
                    <TextField
                      label="Brand Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      name="brandName"
                      value={formData.brandName}
                      onChange={handleChange}
                    />
                    <TextField
                      label="City"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      type=""
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                    />
                  </Box>

                  {/* Submit Button */}
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      width: "100%",
                      height: "50px",
                      marginTop: "20px",
                      backgroundColor: "rgb(108 65 255)",
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

function renderContactInfo(
  icon,
  title,
  content1,
  content2,
  content3,
  link1,
  link2,
  link3
) {
  return (
    <Box className="contact-info-item">
      {React.createElement(icon, {
        className: "contact-icon",
      })}
      <Box>
        <Typography
          variant="h5"
          style={{ color: "white", marginBottom: "10px" }}
        >
          {title}
        </Typography>
        <Typography style={{ color: "grey", width: "100%" }}>
          <ul style={{ paddingLeft: "0px", display: "flex" }}>
            <li>
              {" "}
              <a
                href={link1}
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {content1}&nbsp;
              </a>
            </li>
            {content2 && (
              <>
                <li style={{ borderInline: "2px solid #808080" }}>
                  <a
                    href={link2}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &nbsp;{content2}&nbsp;
                  </a>
                </li>
                <li>
                  <a
                    href={link3}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &nbsp;{content3}
                  </a>
                </li>
              </>
            )}
          </ul>
        </Typography>
      </Box>
    </Box>
  );
}

export default withStyles(styles)(ContactUs);