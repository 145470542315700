
export const fetchAiResponse = async (inputText) => {
    const headers = {
      "Content-Type": "application/json",
    };
  
    const body = {
      request: "review reply",
      review: inputText,
    };
  
    const response = await fetch(
      "https://prod.restaverse.com/api/review/website-ai-review-reply",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      }
    );
  
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return JSON.parse(data.body).reply_1;
  };
  
  
  export const incrementLogin = async (email, queryType) => {
    const headers = {
      "Content-Type": "application/json",
    };
  
    const body = {
      email: email,
      queryType: queryType,
    };
  
    const response = await fetch("https://prod.restaverse.com/api/login_increment", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    return response.json();
  };
  
  

